.sticky-footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #f5f5f5;
  /*height: 30px;*/
  /*line-height: 30px;*/
  z-index: 15;
}

.messages-list {
  list-style-type: none;
  padding: 0;
}

.messages-container {
  max-height: 200px; /* Adjust this value as per your desired height */
  overflow-y: scroll;
}